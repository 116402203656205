<template>
	<div>
		<pui-datatable :modelName="modelName" :actions="actions" :modelColumnDefs="modelColumnDefs" :navigableDetail="false"></pui-datatable>
		<messages-modals :modelName="modelName" :navigableDetail="false"></messages-modals>
	</div>
</template>

<script>
import messagesActions from './MessagesActions';
import messagesModals from './MessagesModals.vue';
import PuiGridDetailMixin from 'pui9-mixins/PuiGridDetailMixin';

export default {
	name: 'messages-grid',
	mixins: [PuiGridDetailMixin],
	components: {
		'messages-modals': messagesModals
	},
	data() {
		return {
			modelName: 'messages',
			actions: messagesActions.gridactions,
			modelColumnDefs: {}
		};
	}
};
</script>
