import utilActions from '@/api/actions';

const messageProcessor = {
	id: 'messageProcessor',
	selectionType: 'multiple',
	label: 'messages.messageProcessor',
	functionality: 'READ_MESSAGES',
	checkAvailability: function (registries) {
		return registries.statuscode == 'PENDING' || registries.statuscode == 'ERROR' ? true : false;
	},
	runAction: function (action, model, registries) {
		const data = { registries, isAction: true };
		this.$puiEvents.$emit('pui-modalDialogForm-messageProcessor-' + model.name + '-show', data);
	}
};

const seeMessage = {
	id: 'seeMessage',
	selectionType: 'single',
	label: 'messages.seeMessage',
	functionality: 'READ_MESSAGES',
	checkAvailability: function () {
		return true;
	},
	runAction: function (action, model, registries) {
		const row = {};
		const objectPk = utilActions.getPkFromModel(model, registries[0]);
		row.headerPk = objectPk;
		row.isAction = true;
		this.$puiEvents.$emit('pui-modalDialogForm-seeMessage-' + model.name + '-show', row);
	}
};

export default {
	gridactions: [seeMessage, messageProcessor]
};
