<template>
	<div>
		<pui-modal-dialog-form
			:modelName="modelName"
			:cancelText="$t('messages.oncancelmessagetext')"
			:okText="$t('messages.onoktext')"
			:titleText="$t('messages.modaltext')"
			:dialogName="dialogNameProcessMessage"
			:onOk="onOkSendMessage"
			><template slot="message">
				<v-container>{{ $t('messages.actionprocessmessagetext') }}</v-container>
			</template>
		</pui-modal-dialog-form>

		<pui-modal-dialog-form
			:modelName="modelName"
			:titleText="$t('messages.seemessagetext')"
			:dialogName="dialogNameSeeMessage"
			widthDialog="30vw"
			disable-cancel
			:onShow="onShowSeeMessage"
			:okText="$t('messages.onclosetext')"
			><template slot="message">
				<v-layout wrap>
					<v-flex xs12>
						<pui-text-area :id="`messages-messagecontent`" v-model="messagecontent" toplabel outlined solo disabled></pui-text-area>
					</v-flex>
				</v-layout>
			</template>
		</pui-modal-dialog-form>
	</div>
</template>

<script>
export default {
	name: 'messages-modals',
	data() {
		return {
			dialogNameProcessMessage: 'messageProcessor',
			dialogNameSeeMessage: 'seeMessage',
			messagecontent: null
		};
	},
	props: {
		modelName: {
			required: true,
			type: String
		}
	},
	methods: {
		onOkSendMessage(modalData) {
			const pks = modalData.registries.map(function (e) {
				return { messageid: e.messageid };
			});

			return new Promise((resolve) => {
				this.$puiRequests.postRequest(
					this.$store.getters.getModelByName(this.modelName).url.sendMessage,
					pks,
					() => {
						this.$puiNotify.success(this.$puiI18n.t('puiaction.notifySuccess'), this.$puiI18n.t('messages.processmessage'));
						this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
						this.$puiEvents.$emit('onPui-action-running-showFormLoading-' + this.modelName);
						resolve(true);
					},
					(e) => {
						this.$puiNotify.error(e.response.data.message, this.$puiI18n.t('messages.processmessage'), 2000, true);
						this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
						this.$puiEvents.$emit('onPui-action-running-showFormLoading-' + this.modelName);

						resolve(false);
					},
					null
				);
			});
		},
		onShowSeeMessage(modalData) {
			const opts = {
				model: 'messages',
				filter: {
					groupOp: 'and',
					groups: [],
					rules: [
						{
							field: 'messageid',
							op: 'eq',
							data: modalData.headerPk.messageid
						}
					]
				}
			};
			this.$puiRequests.postRequest('/puisearch', opts, (response) => {
				this.messagecontent = response.data.data[0].content;
			});
		}
	}
};
</script>
